import HTTP from './HTTP';

const URL_BASE = 'voice/';

export async function GetAppointmentSessions(id) {
  const response = await HTTP.get(`${URL_BASE}${id}/sessions`);
  return response;
}
export async function callAppointment(id) {
  const response = await HTTP.post(`${URL_BASE}init/${id}`);
  return response;
}

export async function getInboundCalls(type) {
  const response = await HTTP.get(`${URL_BASE}inbounds`, {
    params: {
      type,
    },
  });
  return response;
}
