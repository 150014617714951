<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title"><slot name="header">Appointment sessions</slot> </h4>
    </div>
    <div class="card-content">
      <div v-if="showAppointment">
        <div class="columns">
          <div class="column is-6">
            <small>Doctor</small>
            <h3>{{ appointment.doctorName }}</h3>
            <small>{{ appointment.doctorPhone }}</small>
          </div>
          <div class="column is-6">
            <small>Patient</small>
            <h3>{{ appointment.patientName }}</h3>
            <small>{{ appointment.patientPhone }}</small>
          </div>
        </div>
        <div class="my-2">
          <small>Description</small>
          <h3>{{ appointment.patientDesc ? appointment.patientDesc : '--' }}</h3>
        </div>
      </div>
      <div>
        <div v-if="!sessions.length && !loading" class="box">
          <p class="text-center">No session for the appointment</p>
        </div>
        <div v-else>
            <appointment-session v-for="(session, i) in sessions" :key="i" :session="session" />
        </div>
        <b-loading :is-full-page="false" v-model="loading" ></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
import { GetAppointmentSessions } from '@/api/voice';
import AppointmentSession from './AppointmentSession';

export default {
  components: { AppointmentSession },
  name: 'AppointmentSessions',
  props: {
    appointment: Object,
    showAppointment: Boolean,
  },
  data() {
    return {
      loading: false,
      sessions: [],
    };
  },
  methods: {
    async getSessions(id) {
      if (id) {
        this.loading = true;
        this.sessions = (await GetAppointmentSessions(id)).data;
        this.loading = false;
      }
    },
  },
  watch: {
    appointment: {
      immediate: true,
      handler(appointment) {
        if (appointment.appointmentId) this.getSessions(appointment.appointmentId);
      },
    },
  },
};

</script>
