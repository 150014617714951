<template>
  <div v-if="appointment">
    <div class="px-2">
      <div class="columns is-align-items-center is-justify-content-space-between">
        <div class="column is-flex is-align-items-center">
          <h1 class="is-size-4 has-text-weight-medium">
            Appointment
          </h1>
          <b-tag v-if="appointment.sessionCompleted"
          type="is-success" class="ml-5">Session completed</b-tag>
          <b-tag v-else
          type="is-danger" class="ml-5">Session incomplete</b-tag>
          <b-button  @click="getAppointment"
          class="button ml-3" title="refresh">
            <b-icon
            icon="refresh"
            ></b-icon>
          </b-button>
        </div>
        <div class="column">
          <div v-if="profilUser === profilAdmin">
            <b-button size="is-small"
            class="m-1"
            icon-left="pencil"
            :disabled="appointment.sessionCompleted"
            @click="$router.push({
              name: 'EditAppointmentAdmin',
              params: {
                appointmentId: appointment.appointmentId
              }})">
              Edit
            </b-button>

            <b-button size="is-small"
            class="m-1"
            icon-left="account"
            v-if="!appointment.doctorId"
            @click="addDoctor = true">
              Assign Doctor
            </b-button>

            <b-button size="is-small"
            icon-left="phone"
            class="m-1"
            @click="actionCallAppointment">
              Start Session Now
            </b-button>

            <b-button v-if="appointment.sessionCompleted"
            size="is-small"
            icon-left="lock-open"
            class="m-1"
            @click="actionCustomUpdateAppointment({ sessionCompleted: false })">
              Reopen session
            </b-button>

            <b-button v-else
            size="is-small"
            icon-left="lock"
            class="m-1"
            @click="actionCustomUpdateAppointment({ sessionCompleted: true })">
              Close session
            </b-button>
          </div>
          <div v-else-if="profilUser === profilPatient">
            <b-button size="is-small"
            class="m-1"
            icon-left="pencil"
            :disabled="appointment.sessionCompleted"
            @click="$router.push({
              name: 'EditAppointment',
              params: {
                appointmentId: appointment.appointmentId
              }})">
              Edit
            </b-button>

            <b-button
            size="is-small"
            icon-left="credit-card"
            v-if="!appointment.isPay"
            @click="actionPay">
              Make Payment
            </b-button>
          </div>
        </div>
      </div>
      <div class="columns is-justify-content-center">
        <div class="column is-6">
          <div v-for="(detail, i) in details" :key="i">
            <div class="my-2">
              <small class="is-size-7 has-text-grey-light">{{ detail.title }}</small>
              <p>{{ detail.value ? detail.value : '--' }}</p>
              <small v-if="detail.subvalue" class="is-size-6 has-text-grey">
                {{ detail.subvalue }}
              </small>
            </div>
          </div>
          <div class="my-2">
            <small class="is-size-7 has-text-grey-light">Payment</small>
            <div class="is-flex is-align-items-center">
              <b-tag type="is-success" v-if="appointment.isPay">
                <span v-show="!appointment.referencePay">Manually </span>Paid
              </b-tag>
              <b-tag type="is-danger" v-else>Not Paid</b-tag>
              <b-button
              size="is-small"
              class="ml-2"
              icon-left="check"
              v-if="!appointment.isPay && profilUser === profilAdmin"
              @click="actionCustomUpdateAppointment({ isPay: true })">
                Mark as paid
              </b-button>
            </div>
          </div>
        </div>
        <div class="column is-6 sessions-container" >
          <appointment-sessions :appointment="appointment">
            <template #header>
              Sessions
            </template>
          </appointment-sessions>
        </div>
      </div>
    </div>
    <b-modal
      v-model="addDoctor"
      :width="600"
      >
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Add doctor
            </p>
          </header>
          <div class="card-content" style="min-height: 200px">
            <b-field>
              <b-autocomplete
                v-model="searchDoctor"
                :data="filteredDataDoctor"
                icon="magnify"
                field="name"
                clearable
                open-on-focus
                @select="option => doctorSelect = option">
                <template #empty>No results found</template>
              </b-autocomplete>
            </b-field>
          </div>
          <footer class="card-footer">
            <a
              class="card-footer-item has-text-primary"
              @click="addDoctor = false">Cancel</a>
            <a
              class="card-footer-item has-background-primary has-text-white"
              @click="actionAssignDoctor">Assign</a>
          </footer>
        </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import {
  GetAppointment,
  PayAppointment,
  AssignDoctor,
  customUpdateAppointmentAdmin,
} from '@/api/appointment';
import { callAppointment } from '@/api/voice';
import { GetUserDoctor } from '@/api/user';
import AppointmentSessions from '@/components/AppointmentSessions';
import { PATIENT, ADMIN, DOCTOR } from '@/config/user';

export default {
  metaInfo() {
    return {
      title: `Appointment ${this.appointment ? `for ${this.appointment.patientName}` : ''}`,
    };
  },
  components: { AppointmentSessions },
  data() {
    return {
      appointment: null,
      loading: false,
      addDoctor: false,
      profilPatient: PATIENT,
      profilAdmin: ADMIN,
      profilDoctor: DOCTOR,
      doctorSelect: null,
      doctors: [],
      searchDoctor: '',
    };
  },
  computed: {
    ...mapGetters({
      profilUser: 'profil',
    }),
    id() {
      return this.$route.params.id;
    },
    details() {
      if (!this.appointment) return [];
      return [
        {
          title: 'Doctor',
          value: this.appointment.doctorName,
          subvalue: this.appointment.doctorPhone,
        },
        {
          title: 'Patient',
          value: this.appointment.patientName,
          subvalue: this.appointment.patientPhone,
        },
        {
          title: 'Description',
          value: this.appointment.patientDesc,
        },
        {
          title: 'Time',
          value: moment(this.appointment.startAt).format('LLL'),
        },
        {
          title: 'Created',
          value: moment(this.appointment.createdAt).format('LLL'),
        },
      ];
    },
    filteredDataDoctor() {
      return this.doctors
        .filter((el) => {
          return el.name
            .toString()
            .toLowerCase()
            .indexOf(this.searchDoctor.toLowerCase()) >= 0;
        });
    },
  },
  methods: {
    getAppointment() {
      const loadingComponent = this.$buefy.loading.open();
      GetAppointment(this.id).then(response => {
        this.appointment = response.data.appointment;
      })
        .catch(e => {
          this.showError(e);
        })
        .finally(() => {
          loadingComponent.close();
        });
    },
    async actionPay() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const url = (await PayAppointment(
          this.appointment.appointmentId,
          this.appointment.referencePay,
        )).data;
        loadingComponent.close();
        window.location = url;
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
      }
    },
    async actionAssignDoctor() {
      if (!this.doctorSelect) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Select doctor',
          position: 'is-bottom',
          type: 'is-danger',
        });
        return false;
      }
      const loadingComponent = this.$buefy.loading.open();
      try {
        await AssignDoctor(
          this.appointment.appointmentId,
          this.doctorSelect.userId,
        );
        loadingComponent.close();
        this.addDoctor = false;
        this.getAppointment();
        return true;
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return false;
      }
    },

    actionCallAppointment() {
      const loadingComponent = this.$buefy.loading.open();
      callAppointment(this.id).then(response => {
        if (response.data && response.data.entries.length) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Calls sent',
            position: 'is-bottom',
            type: 'is-success',
          });
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Calls were not sent',
            position: 'is-bottom',
            type: 'is-danger',
          });
        }
      })
        .catch(e => {
          this.showError(e);
        })
        .finally(() => {
          loadingComponent.close();
        });
    },

    actionCustomUpdateAppointment(updates) {
      const loadingComponent = this.$buefy.loading.open();
      customUpdateAppointmentAdmin(this.id, updates).then(response => {
        this.appointment = response.data.appointment;
      })
        .catch(e => {
          this.showError(e);
        })
        .finally(() => {
          loadingComponent.close();
        });
    },
  },

  async mounted() {
    if (this.profilUser === ADMIN) {
      const resdOC = (await GetUserDoctor()).data;
      this.doctors = resdOC.users
        .map(el => ({
          ...el,
          name: `${el.firstName} ${el.lastName}`,
        }));
    }
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        if (this.$route.params.data) {
          this.appointment = this.$route.params.data;
        } else {
          this.getAppointment();
        }
      },
    },
  },
};
</script>

<style>
@media screen and (min-width: 768px) {
  .sessions-container
  {
    max-height: 70vh;
    overflow-y: auto;
  }
}

</style>
