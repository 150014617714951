<template>
    <div v-if="stream" class="is-flex is-justify-content-space-between is-align-content-center">
      <audio controls v-bind="$attrs">
        <source :src="stream" type="audio/mpeg" >
        Your browser does not support the audio element.
      </audio>
      <slot name="actions" />
    </div>
    <b-message v-else type="is-warning" has-icon>
      There is no audio
    </b-message>
</template>

<script>
export default {
  name: 'AudioPlayer',
  props: {
    source: String,
  },
  computed: {
    stream() {
      if (!this.source) return null;
      return `${process.env.VUE_APP_BASE_URL_API}media?url=${this.source}`;
    },
  },
};
</script>
