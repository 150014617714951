<template>
    <div v-if="session" class="box my-2">
      <div>
        <h4 class="is-flex is-align-content-center">
          <b-icon icon="clock" class="mr-2"></b-icon>{{ session.callStartTime }}
        </h4>
        <hr>
        <audio-player :source="session.recordingUrl">
          <template #actions>
            <button class="button is-small mt-2" @click="fullDetails = !fullDetails">
              {{ fullDetails ? 'Show less' : 'More info'}}
            </button>
          </template>
        </audio-player>
        <div v-if="fullDetails" class="mt-3">
          <div v-for="(detail, i) in details" :key="i">
            <div class="is-flex is-justify-content-space-between">
              <div>{{ detail.title }}</div>
              <div>{{ detail.value }}</div>
            </div>
            <hr class="my-3" v-if="i < (details.length -1 )">
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import AudioPlayer from './AudioPlayer';

export default {
  name: 'AppointmentSession',
  components: { AudioPlayer },
  props: {
    session: Object,
  },
  data() {
    return {
      fullDetails: false,
    };
  },
  computed: {
    details() {
      if (!this.session) return [];
      return [
        {
          title: 'Call start time',
          value: this.session.callStartTime,
        },
        {
          title: 'Conference participation duration',
          value: `${this.session.conferenceParticipantDuration} sec`,
        },
        {
          title: 'Destination number',
          value: this.session.destinationNumber,
        },
        {
          title: 'Call session state',
          value: this.session.callSessionState,
        },
        {
          title: 'Status',
          value: this.session.status,
        },
        {
          title: 'Cost',
          value: `${this.session.currencyCode}${this.session.amount}`,
        },
      ];
    },
  },
};
</script>
